.buttonFade {
  -webkit-animation: flickerAnimation 1.5s infinite;
  -moz-animation: flickerAnimation 1.5s infinite;
  -o-animation: flickerAnimation 1.5s infinite;
  animation: flickerAnimation 1.5s infinite;
}

@keyframes flickerAnimation {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.3;
  }
}
@-o-keyframes flickerAnimation {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.3;
  }
}
@-moz-keyframes flickerAnimation {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.3;
  }
}
@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.3;
  }
}
